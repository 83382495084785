const apiHost = "sf6.combocentral.com"
const frontEndUrl = "combocentral.com"
const envId = "sf6"
const enableCors = false
const httpType = "https"
const mongoAuthString = "admin:f4439e2c4a1e32c80422c6b8c422d792d5d0a7c418ac0fb8"
const adSenseTestOnly = false


module.exports = {
    apiHost,
    frontEndUrl,
    envId,
    enableCors,
    httpType,
    mongoAuthString,
    adSenseTestOnly
}