// class Combo {
//     constructor(comboPieces, comments, metrics) {
//         this.comboPieces = comboPieces
//         this.comments = comments
//         this.metrics = metrics
//     }
// }

// class ComboPiece {
//     constructor(tips, inputs) {
//         this.tips = tips
//         this.inputs = inputs
//     }
// }

// class Input {
//     constructor(button, command, name) {
//         this.button = button
//         this.command = command
//         this.name = name
//         this.nickname
//     }
// }

const ComboDetailType = {
  String: "string",
  String_Array: "string_array",
  String_Multi_Select: "string_multi_select",
  String_Single_Select: "string_single_select",
  Number: "number",
  Char_Array: "character_array",
};

export { ComboDetailType };
