// The buttons the game uses. For games like undernight the bumpers/triggers should be removed
const supportedButtons = ["A", "B", "C", "D", "RB", "RT", "LB", "LT",
  "A:(hold)", "B:(hold)", "C:(hold)", "D:(hold)", "RB:(hold)", "RT:(hold)", "LB:(hold)", "LT:(hold)",
  "A:(short hold)", "B:(short hold)", "C:(short hold)", "D:(short hold)", "RB:(short hold)", "RT:(short hold)", "LB:(short hold)", "LT:(short hold)",
  "A:(long hold)", "B:(long hold)", "C:(long hold)", "D:(long hold)", "RB:(long hold)", "RT:(long hold)", "LB:(long hold)", "LT:(short hold)",
  "A:(delay)", "B:(delay)", "C:(delay)", "D:(delay)", "RB:(delay)", "RT:(delay)", "LB:(delay)", "LT:(delay)",
  "A:(same time as direction press)", "B:(same time as direction press)", "C:(same time as direction press)", "D:(same time as direction press)", "RB:(same time as direction press)", "RT:(same time as direction press)", "LB:(same time as direction press)", "LT:(same time as direction press)",
  "A:(long delay)", "B:(long delay)", "C:(long delay)", "D:(long delay)", "RB:(long delay)", "RT:(same time as direction press)", "LB:(same time as direction press)", "LT:(same time as direction press)",
  "A:(timed hold)", "B:(timed hold)", "C:(timed hold)", "D:(timed hold)", "RB:(timed hold)", "RT:(timed hold)", "LB:(timed hold)", "LT:(timed hold)",
];
const supportedOkiTags = [
  "soft knockdown",
  "hard knockdown",
  "safe jump",
  "pressure",
  "mixup",
  "setplay",
]

const suggestedPiecePlayerStates = ["jumping", "drive rushing"]

const envListOverride = null

const characterData = {
  "ken-masters": {
    color: "#c23c3c",
    secondaryColor: "#823e3e",
    textColor: "#f5856e",
    darkColor: "#8c3030",
    darkerColor: "#381111",
    id: "ken-masters",
    name: "Ken Masters",
  },
  "aki": {
    color: "#b3446c",
    secondaryColor: "#c26586",
    textColor: "#e07ba0",
    darkerColor: "#4f1329",
    darkColor: "#803751",
    id: "aki",
    name: "A.K.I",
  },
  "cammy-white": {
    color: "#355f97",
    secondaryColor: "#466691",
    textColor: "#81a8de",
    darkerColor: "#112c4f",
    darkColor: "#1a437a",
    id: "cammy-white",
    name: "Cammy White",
  },
  "ed": {
    color: "#763d8a",
    secondaryColor: "#6b3c7a",
    textColor: "#da9ded",
    darkerColor: "#31133b",
    darkColor: "#562866",
    id: "ed",
    name: "Ed",
  },
  "dhalsim": {
    color: "#996220",
    secondaryColor: "#9e713a",
    textColor: "#edce95",
    darkerColor: "#3b2409",
    darkColor: "#663f10",
    id: "dhalsim",
    name: "Dhalsim",
  },
  "blanka": {
    color: "#7a7428",
    secondaryColor: "#8a843f",
    textColor: "#ede480",
    darkerColor: "#36320d",
    darkColor: "#595415",
    id: "blanka",
    name: "Blanka",
  },
  "ryu": {
    color: "#7a626d",
    secondaryColor: "#6e6066",
    textColor: "#d9bac8",
    darkerColor: "#36292e",
    darkColor: "#634954",
    id: "ryu",
    name: "Ryu",
  },
  "guile": {
    color: "#15759e",
    secondaryColor: "#2a6f8c",
    textColor: "#aadbf0",
    darkerColor: "#063a4f",
    darkColor: "#0f5875",
    id: "guile",
    name: "Guile",
  },
  "jamie": {
    color: "#827e2f",
    secondaryColor: "#66643a",
    textColor: "#fffba6",
    darkerColor: "#42400f",
    darkColor: "#5c591c",
    id: "jamie",
    name: "Jamie Siu",
  },
  "jp": {
    color: "#786c68",
    secondaryColor: "#7d7674",
    textColor: "#fae5de",
    darkerColor: "#332c2a",
    darkColor: "#4f4946",
    id: "jp",
    name: "JP",
  },
  "lily": {
    color: "#446E8B",
    secondaryColor: "#5b7282",
    textColor: "#b0d9f5",
    darkerColor: "#17415e",
    darkColor: "#314654",
    id: "lily",
    name: "Lily Hawk",
  },
  "manon": {
    color: "#67799e",
    secondaryColor: "#5d6980",
    textColor: "#6a7791",
    darkerColor: "#101c36",
    darkColor: "#3a4969",
    id: "manon",
    name: "Manon Legrand",
  },
  "marisa": {
    color: "#c44949",
    secondaryColor: "#b34747",
    textColor: "#ed6f6f",
    darkerColor: "#520d0d",
    darkColor: "#702e2e",
    id: "marisa",
    name: "Marisa Rossetti",
  },
  "rashid": {
    color: "#8a7534",
    secondaryColor: "#827241",
    textColor: "#fae398",
    darkerColor: "#292107",
    darkColor: "#665522",
    id: "rashid",
    name: "Rashid",
  },
  "zangief": {
    color: "#963e3e",
    secondaryColor: "#b84848",
    textColor: "#fa7f7f",
    darkerColor: "#4f0a0a",
    darkColor: "#662e2e",
    id: "zangief",
    name: "Zangief",
  },
  "kimberly": {
    color: "#c96632",
    secondaryColor: "#9e6041",
    textColor: "#f5a67d",
    darkerColor: "#632c10",
    darkColor: "#9e4416",
    id: "kimberly",
    name: "Kimberly Jackson",
  },
  "juri": {
    color: "#bd4d8e",
    secondaryColor: "#ab608c",
    textColor: "#f5b0d8",
    darkerColor: "#360923",
    darkColor: "#801955",
    id: "juri",
    name: "Juri Han",
  },
  "ehonda": {
    color: "#b83d39",
    secondaryColor: "#9e4946",
    textColor: "#f57e7a",
    darkerColor: "#54100d",
    darkColor: "#5e2f2d",
    id: "ehonda",
    name: "Edmond Honda",
  },
  "akuma": {
    color: "#6b6b6b",
    secondaryColor: "#706e6e",
    textColor: "#dbd3d3",
    darkerColor: "#242424",
    darkColor: "#4a4a4a",
    id: "akuma",
    name: "Akuma",
  },
  "luke-sullivan": {
    color: "#474d8f",
    secondaryColor: "#484b75",
    textColor: "#999ff2",
    darkerColor: "#0c0d24",
    darkColor: "#32356e",
    id: "luke-sullivan",
    name: "Luke Sullivan",
  },
  "chun-li": {
    color: "#326a9c",
    secondaryColor: "#4c6d8c",
    darkColor: "#213b52",
    darkerColor: "#112230",
    textColor: "#7dbffa",
    id: "chun-li",
    name: "Chun-Li",
  },
  "dee-jay": {
    color: "#807233",
    id: "dee-jay",
    secondaryColor: "#7a7145",
    darkColor: "#635a2e",
    darkerColor: "#47401f",
    textColor: "#e3d48a",
    name: "Dee Jay",
  },
  "mbison": {
    id: "mbison",
    color: "#864e96",
    secondaryColor: "#6d5175",
    darkColor: "#653275",
    darkerColor: "#220b29",
    textColor: "#f3e4f7",
    name: "M. Bison",
  },
};

const gameData = {
  color: "#3750ab",
  secondaryColor: "#bd7a2d",
  defaultCharacterData: {
    color: "#3750ab",
    id: null,
    secondaryColor: "#bd7a2d",
    darkColor: "#3750ab",
    darkerColor: "#3750ab",
    textColor: "#3750ab",
    name: "",
  },
};

module.exports = {
  supportedButtons,
  supportedOkiTags,
  characterData,
  gameData,
  suggestedPiecePlayerStates,
  envListOverride
};
