// The buttons the game uses. For games like undernight the bumpers/triggers should be removed
const supportedButtons = ["A", "B", "C", "D", "RB", "RT", "LB", "LT", "A:(hold)"];
const supportedOkiTags = [
  "soft knockdown",
  "hard knockdown",
  "safe jump",
  "pressure",
  "mixup",
  "setplay",
]

const suggestedPiecePlayerStates = ["jumping", "drive rushing"]

// Leave empty for production environemnts
const envListOverride = null
// [
//   { name: "STREET FIGHTER 6", subDomain: "sf6", color: "#3750ab" },
//   { name: "GUILTY GEAR XRD", subDomain: "ggrev2", color: "#910a11" },
// ]

const characterData = {
  "ken-masters": {
    color: "#c23c3c",
    secondaryColor: "#823e3e",
    textColor: "#f5856e",
    darkColor: "#8c3030",
    darkerColor: "#381111",
    id: "ken-masters",
    name: "Ken Masters",
  },
  "aki": {
    color: "#b3446c",
    secondaryColor: "#c26586",
    textColor: "#e07ba0",
    darkerColor: "#4f1329",
    darkColor: "#803751",
    id: "aki",
    name: "A.K.I",
  },
  "cammy-white": {
    color: "#355f97",
    secondaryColor: "#466691",
    textColor: "#81a8de",
    darkerColor: "#112c4f",
    darkColor: "#1a437a",
    id: "cammy-white",
    name: "Cammy White",
  },
  "ed": {
    color: "#763d8a",
    secondaryColor: "#6b3c7a",
    textColor: "#da9ded",
    darkerColor: "#31133b",
    darkColor: "#562866",
    id: "ed",
    name: "Ed",
  },
  "dhalsim": {
    color: "#996220",
    secondaryColor: "#9e713a",
    textColor: "#edce95",
    darkerColor: "#3b2409",
    darkColor: "#663f10",
    id: "dhalsim",
    name: "Dhalsim",
  },
  "blanka": {
    color: "#7a7428",
    secondaryColor: "#9c9548",
    textColor: "#ede480",
    darkerColor: "#36320d",
    darkColor: "#595415",
    id: "blanka",
    name: "Blanka",
  },
  "ryu": {
    color: "#7a626d",
    secondaryColor: "#8f7e85",
    textColor: "#d9bac8",
    darkerColor: "#36292e",
    darkColor: "#634954",
    id: "ryu",
    name: "Ryu",
  },
  "guile": {
    color: "#1489ba",
    secondaryColor: "#348db3",
    textColor: "#aadbf0",
    darkerColor: "#063a4f",
    darkColor: "#105e7d",
    id: "guile",
    name: "Guile",
  },
  "jamie": {
    color: "#ada840",
    secondaryColor: "#b8b363",
    textColor: "#fffba6",
    darkerColor: "#42400f",
    darkColor: "#948f2b",
    id: "jamie",
    name: "Jamie Siu",
  },
  "jp": {
    color: "#8f807c",
    secondaryColor: "#a19895",
    textColor: "#fae5de",
    darkerColor: "#332c2a",
    darkColor: "#736a67",
    id: "jp",
    name: "JP",
  },
  "lily": {
    color: "#446E8B",
    secondaryColor: "#688599",
    textColor: "#b0d9f5",
    darkerColor: "#17415e",
    darkColor: "#4a7491",
    id: "lily",
    name: "Lily Hawk",
  },
  "manon": {
    color: "#768ab3",
    secondaryColor: "#8f9bb3",
    textColor: "#cbd9f5",
    darkerColor: "#26324a",
    darkColor: "#65779c",
    id: "manon",
    name: "Manon Legrand",
  },
  "marisa": {
    color: "#b32e2e",
    secondaryColor: "#b34747",
    textColor: "#ed6f6f",
    darkerColor: "#520d0d",
    darkColor: "#8f0a0a",
    id: "marisa",
    name: "Marisa Rossetti",
  },
  "marisa": {
    color: "#b32e2e",
    secondaryColor: "#b34747",
    textColor: "#ed6f6f",
    darkerColor: "#520d0d",
    darkColor: "#8f0a0a",
    id: "marisa",
    name: "Marisa Rossetti",
  },
  "rashid": {
    color: "#b3973e",
    secondaryColor: "#ad995a",
    textColor: "#fae398",
    darkerColor: "#45380e",
    darkColor: "#8f772c",
    id: "rashid",
    name: "Rashid",
  },
  "zangief": {
    color: "#b82727",
    secondaryColor: "#b84848",
    textColor: "#fa7f7f",
    darkerColor: "#4f0a0a",
    darkColor: "#8c1616",
    id: "zangief",
    name: "Zangief",
  },
  "kimberly": {
    color: "#c96632",
    secondaryColor: "#c77c56",
    textColor: "#f5a67d",
    darkerColor: "#632c10",
    darkColor: "#9e4416",
    id: "kimberly",
    name: "Kimberly Jackson",
  },
  "juri": {
    color: "#bd4d8e",
    secondaryColor: "#ab608c",
    textColor: "#f5b0d8",
    darkerColor: "#360923",
    darkColor: "#801955",
    id: "juri",
    name: "Juri Han",
  },
  "ehonda": {
    color: "#b83d39",
    secondaryColor: "#9e4946",
    textColor: "#f57e7a",
    darkerColor: "#54100d",
    darkColor: "#9c1f1a",
    id: "ehonda",
    name: "Edmond Honda",
  },
  "akuma": {
    color: "#6b6b6b",
    secondaryColor: "#858585",
    textColor: "#dbd3d3",
    darkerColor: "#242424",
    darkColor: "#4a4a4a",
    id: "akuma",
    name: "Akuma",
  },
  "luke-sullivan": {
    color: "#3038a1",
    secondaryColor: "#484b75",
    textColor: "#999ff2",
    darkerColor: "#0c0d24",
    darkColor: "#272b6b",
    id: "luke-sullivan",
    name: "Luke Sullivan",
  },
  "chun-li": {
    color: "#326a9c",
    secondaryColor: "#597d9e",
    darkColor: "#213b52",
    darkerColor: "#112230",
    textColor: "#7dbffa",
    id: "chun-li",
    name: "Chun-Li",
  },
  "dee-jay": {
    color: "#95863d",
    id: "dee-jay",
    secondaryColor: "#9c915f",
    darkColor: "#635a2e",
    darkerColor: "#47401f",
    textColor: "#e3d48a",
    name: "Dee Jay",
  },
};

const gameData = {
  color: "#3750ab",
  secondaryColor: "#bd7a2d",
  defaultCharacterData: {
    color: "#3750ab",
    id: null,
    secondaryColor: "#bd7a2d",
    darkColor: "#3750ab",
    darkerColor: "#3750ab",
    textColor: "#3750ab",
    name: "",
  },
};

module.exports = {
  supportedButtons,
  supportedOkiTags,
  characterData,
  gameData,
  suggestedPiecePlayerStates,
  envListOverride
};
